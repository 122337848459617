import '../styles/globals.css'
import type { ReactElement, ReactNode } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
// import Layout from '../components/layout'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import localFont from '@next/font/local'

const inter = localFont({
  preload: true,
  fallback: ['system-ui'],

  adjustFontFallback: false,
  display: 'swap',
  src: [
    {
      path: '../public/assets/fonts/Inter-Regular.woff2',
      weight: '400',
    },
    {
      path: '../public/assets/fonts/Inter-Medium.woff2',
      weight: '500',
    },
    {
      path: '../public/assets/fonts/Inter-SemiBold.woff2',
      weight: '600',
    },
    {
      path: '../public/assets/fonts/Inter-Bold.woff2',
      weight: '700',
    },
    {
      path: '../public/assets/fonts/Inter-ExtraBold.woff2',
      weight: '800',
    },
  ],
})

const Layout = dynamic(() => import('../components/layout'))

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? (page => page)

  return getLayout(
    <div className={inter.className}>
      <Layout>
        <Head>
          <meta name='viewport' content='width=device-width, initial-scale=1' />
        </Head>
        <Component {...pageProps} />
      </Layout>
    </div>,
  )
}
